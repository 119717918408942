const API_URL = window.envvars.API_URL;

const post = async (path, body) => {
  const response = await fetch(`${API_URL}${path}`, {
    method: 'POST',
    cache: 'no-cache',
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
    },
  });
  if (response.ok) {
    return response.json();
  } else {
    throw new Error('Something went wrong.');
  }
};

export const getApplication = async (bundle, applicant) => {
  return await post('/v1/skills/application', { bundle, applicant });
};

export const getNextQuestion = async (params) => {
  return await post('/v1/skills/next-question', params);
};

export const getPreviewQuestion = async (question) => {
  return await post('/v1/skills/preview-question', { question });
};
