import React, { useContext, Fragment } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { AssessmentContext } from '../contexts/Assessment';
import { QuestionContext } from '../contexts/Question';
import Markdown from './Markdown';
import Question from './Question';

const Assessment = () => {
  const theme = useContext(ThemeContext);
  const { value: assessment, set: setAssessment } = useContext(AssessmentContext);
  const { value: question, next: nextQuestion } = useContext(QuestionContext);

  const Box = styled.div`
    margin: 5vh auto;
    padding: 2rem;
    max-width: 800px;
    background-color: ${theme.colors.white};
    border-radius: 4px;
    border: 1px solid ${theme.colors.gray};

    h1 {
      margin: 0;
    }
  `;

  const handleClick = () => {
    nextQuestion();
  };

  return (
    <Fragment>
      {assessment && !question && (
        <Box>
          <h1>{assessment.title}</h1>
          <Markdown value={assessment.description} />
          <div className="actions">
            <button className="plain" onClick={() => setAssessment(null)}>Back</button>
            {assessment.status === 'started' && <button className="primary" onClick={handleClick}>Continue</button>}
            {assessment.status === 'todo' && <button className="primary" onClick={handleClick}>Ready to Start!</button>}
          </div>
        </Box>
      )}
      <Question />
    </Fragment>
  );
};

export default Assessment;
