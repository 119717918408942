import React, { useContext, useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';

import { getApplication } from '../api';


const ApplicationContext = React.createContext(null);


const ApplicationContextProvider = ({ children }) => {
  const params = useParams();
  const [state, setState] = useState(null);

  useEffect(
    () => {
      async function fetch() {
        toast.info('Loading your application...', {autoClose: 1500});
        try {
          const status = await getApplication(params.bundle, params.applicant);
          setState(status);
        } catch (error) {
          toast.error('Error loading your application. Please contact the person who provided this assessment.');
          console.log(error);
          setState(null);
        }
      }
      fetch();
      return () => {};
    },
    [params],
  );

  return (
    <ApplicationContext.Provider value={state}>
      {children}
    </ApplicationContext.Provider>
  );
};


const ApplicationContextConsumer = ApplicationContext.Consumer;


const useApplication = () => {
  return useContext(ApplicationContext);
};


export { useApplication, ApplicationContext, ApplicationContextProvider, ApplicationContextConsumer };
export default useApplication;
