const theme = {
  colors: {
    white: '#fff',
    dirty: '#f8fafb',
    smoke: '#f0f4f7',
    stone: '#d6e7ef',
    gray: '#c9d7df',
    black: '#4d6072',
    blue: '#1999e3',
    ocean: '#117cba',
    storm: '#0c6090',
    green: '#4fc510',
    red: '#f83244',
  },
  fonts: {
    default: {
      'family': '-apple-system, BlinkMacSystemFont, \'Segoe UI\', Roboto, \'Helvetica Neue\', Helvetica, Arial, sans-serif',
    },
    code: {
      'family': 'Consolas, Liberation Mono, Menlo, monospace',
    },
  }

};

export default theme;
