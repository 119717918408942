import React, { useContext, Fragment, useState, useEffect } from 'react';
import styled, { ThemeContext } from 'styled-components';
import useAssessment from '../contexts/Assessment';
import { QuestionContext } from '../contexts/Question';
import Markdown from './Markdown';
import Clock from './Clock';


const Question = () => {
  const theme = useContext(ThemeContext);
  const assessment = useAssessment();
  const { value: context, next: nextQuestion } = useContext(QuestionContext);
  const [timeout, setTimeout] = useState(false);
  const [stopped, stop] = useState(false);
  const [fetching, setFetch] = useState(false);
  const [paused, setPause] = useState(false);
  const [choice, setChoice] = useState(null);
  const [progress, setProgress] = useState(0);

  useEffect(
    () => {
      stop(false);
      setTimeout(false);
      setFetch(false);
      setChoice(null);
      setPause((context && context.paused) || false);
      setProgress((context && context.progress) || 0);
      return () => {};
    },
    [context],
  );

  const COLUMNS = {
    '1N': 1,
    '2N': 2,
    '3N': 3,
    '4N': 4,
  };

  const Box = styled.div`
    position: relative;
    margin: 5vh auto;
    padding: 2rem;
    max-width: 800px;
    background-color: ${theme.colors.white};
    border-radius: 4px;
    border: 1px solid ${theme.colors.gray};

    .title {
      display: flex;
      margin: 0;
      justify-content: space-between;
        align-items: center;

      h1 {
        margin: 0;
      }
    }

    > hr {
      margin: 2rem 0 0 0;
      height: 10px;
      border: 0;
      border-radius: 4px;
      background-color: ${theme.colors.smoke};
      background-image: linear-gradient(90deg, ${theme.colors.blue} 0%, ${theme.colors.blue} ${progress}%, ${theme.colors.smoke} ${progress}%, ${theme.colors.smoke} 100%);

    }

    > button {
      position: fixed;
      top: calc(5vh + 20rem);
      left: calc(50vw + 400px + 2rem);
    }

    > .markdown {
      margin: 3rem 0 0 0;

      > p {
        font-size: 2.2rem;
        font-weight: 600;
      }
    }

    > ul {
      margin: 4rem 0 0 0;
      padding: 0;
      list-style-type: none;
      display: grid;
      grid-gap: 2rem;
      grid-template-columns: repeat(${COLUMNS[context && context.question && context.question.display] || 1}, 1fr);

      > li {
        margin: 0;
        padding: 1.5rem;
        border: 1px solid ${theme.colors.gray};
        cursor: pointer;
        font-size: 1.6rem;
        border-radius: 4px;
        ${props => (timeout || stopped) && 'opacity: .5;'}
        ${props => (timeout || stopped) && 'pointer-events: none;'}

        &:hover {
          ${props => !timeout && `border: 1px solid ${theme.colors.blue};`}
        }

        &.selected {
          opacity: 1;
          border: 1px solid ${theme.colors.blue};
        }

        > .markdown {
          margin-top: 0;

          > p {
            font-size: 1.8rem;
            font-weight: 600;
          }
        }
      }
    }
  `;

  const handleChoice = (choice) => {
    stop(true);
    setChoice(choice);
    setFetch(true);
    nextQuestion(choice, paused);
  };

  const handleNextQuestion = () => {
    setFetch(true);
    nextQuestion(null, paused);
  };

  const handleTimeout = () => {
    setPause(false);
    setTimeout(true);
  };

  const handlePause = () => {
    setPause(true);
  };

  return (
    <Fragment>
      {context && context.question && (
        <Box>
          <Clock stopped={stopped} onTimeout={handleTimeout} question={context.question} />
          {timeout && !fetching && (<button className="primary" onClick={handleNextQuestion}>Next Question</button>)}
          {timeout && fetching && (<button className="primary disabled">Next Question</button>)}
          {context.remaining > 1 && !stopped && !timeout && !paused && (<button onClick={handlePause}>I need a break!</button>)}
          {context.remaining > 1 && !stopped && !timeout && paused && (<button className="plain disabled">The assesment will be paused after this question.</button>)}
          <div className="title">
            <h1>{assessment.title}</h1>
            {context.remaining > 0 && (<span>Approximately {context.remaining} question{context.remaining === 1 ? '' : 's'} remaining</span>)}
            {context.remaining === 0 && (<span>Last question.</span>)}
          </div>
          <hr />
          <Markdown className="markdown" value={context.question.text} />
          <ul>
            {(!timeout && !stopped) && context.question.choices.map(item => (
              <li key={item.eid} onClick={() => handleChoice(item.eid)}>
                <Markdown className="markdown" value={item.text} />
              </li>
            ))}
            {(timeout || stopped) && context.question.choices.map(item => (
              <li key={item.eid} className={choice === item.eid ? 'selected' : ''}>
                <Markdown className="markdown" value={item.text} />
              </li>
            ))}
          </ul>
        </Box>
      )}
    </Fragment>
  );
};

export default Question;
