import React, { useContext, useState, useEffect } from 'react';

import useApplication from './Application';

const BundleContext = React.createContext(null);


const BundleContextProvider = ({ children }) => {
  const application = useApplication();
  const [state, setState] = useState(null);

  useEffect(
    () => {
      setState((application && application.bundle) || null);
      return () => {};
    },
    [application],
  );

  return (
    <BundleContext.Provider value={state}>
      {children}
    </BundleContext.Provider>
  );
};


const BundleContextConsumer = BundleContext.Consumer;


const useBundle = () => {
  return useContext(BundleContext);
};


export { useBundle, BundleContext, BundleContextProvider, BundleContextConsumer };
export default useBundle;
