import React, { useContext, useState } from 'react';

const AssessmentContext = React.createContext({ value: null, set: () => {} });


const AssessmentContextProvider = ({ children }) => {
  const [state, setState] = useState(null);

  return (
    <AssessmentContext.Provider value={{ value: state, set: (newState) => setState(newState) }}>
      {children}
    </AssessmentContext.Provider>
  );
};

const AssessmentContextConsumer = AssessmentContext.Consumer;


const useAssessment = () => {
  return useContext(AssessmentContext).value;
};


export { useAssessment, AssessmentContext, AssessmentContextProvider, AssessmentContextConsumer };
export default useAssessment;
