import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';


const Markdown = ({ className, value }) => {

  const theme = useContext(ThemeContext);

  const Box = styled.div`
    margin-top: 2rem;
    font-size: 1.8rem;

    > * {
      margin-top: 1.5rem;
      margin-bottom: 0;
    }

    > :first-child {
      margin-top: 0;
    }

    > :last-child {
      margin-bottom: 0;
    }

    iframe {
      margin-left: auto;
      margin-right: auto;
      display: block;
    }

    img {
      max-width: 100%;
    }

    code {
      font-family: ${props => props.theme.fonts.code.family};
      font-size: 80%;
      font-weight: normal;
      padding: .2rem .4rem;
      background-color: ${theme.colors.dirty};
      border-radius: 3px;
    }

    .code {
      font-family: ${props => props.theme.fonts.code.family};
      font-size: 80%;
      padding: 4px 8px;
      background-color: ${theme.colors.dirty};
      border-radius: 4px;
      line-height: 1.4;
      max-width: 100%;
      overflow: auto;
      word-wrap: normal;

      pre {
        font-family: ${props => props.theme.fonts.code.family};
        display: inline;

        .k, .kc, .kd, .kn, .kp, .kr, .kt {
          color: ${theme.colors.blue};
        }

        .s,
        .sb {
          color: ${theme.colors.green};
        }

        .si {
          color: ${theme.colors.blue};
        }

        .nb,
        .nd,
        .nt {
          color: ${theme.colors.ocean};
        }

        .go {
          color: ${theme.colors.green};
        }

        .c, .cm, .cp, .c1, .cs,
        .sd {
          color: ${theme.colors.gray};
        }
      }
    }
  `;

  return <Box className={className} dangerouslySetInnerHTML={{__html: value}} />;
};

export default Markdown;
