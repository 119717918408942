import React from 'react';
import styled from 'styled-components';
import { ToastContainer, ToastContainerProps } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { ReactComponent as Close } from '../svgs/close.svg';

const CloseButton = ({ type }) => (
  <span className={`Toastify__close-button Toastify__close-button--${type}`}><Close /></span>
);

export const WrappedToastContainer = ({ className, ...rest }: ToastContainerProps & { className?: string }) => (
  <div className={className}>
    <ToastContainer
      position="top-right"
      autoClose={false}
      hideProgressBar={true}
      closeOnClick={true}
      pauseOnHover={false}
      draggable={false}
      closeButton={<CloseButton />}
      {...rest}
    />
  </div>
);

export default styled(WrappedToastContainer).attrs({
  // custom props
})`
  .Toastify__toast-container {
    font-family: 'IBM Plex Sans', -apple-system, BlinkMacSystemFont, 'Helvetica Neue', Helvetica, sans-serif;
    font-size: 14px;
    line-height: 1.4;
    width: 360px;
  }

  .Toastify__toast {
    box-shadow: rgba(0, 0, 0, 0.176) 0px 3px 8px;
    border-radius: 4px;
    padding: 0;
    min-height: auto;

    &::before {
      content: "";
      width: 10px;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
  }
  .Toastify__close-button {
    opacity: 0.5;
    padding: 8px 12px;
    transition: opacity 150ms ease 0s;

    &:hover {
      opacity: 1;
    }
  }

  .Toastify__toast--info {
    background-color: white;
    color: rgb(80, 95, 121);

    &::before {
      background-color: rgb(38, 132, 255);
      color: white;
    }
  }
  .Toastify__close-button--info {
    color: rgb(80, 95, 121);
  }

  .Toastify__toast--error {
    background-color: rgb(255, 235, 230);
    color: rgb(191, 38, 0);

    &::before {
      background-color: rgb(255, 86, 48);
      color: rgb(255, 235, 230);
    }
  }
  .Toastify__close-button--error {
    color: rgb(191, 38, 0);
  }

  .Toastify__toast--warning {
    background-color: rgb(255, 250, 230);
    box-shadow: rgba(0, 0, 0, 0.176) 0px 3px 8px;
    color: rgb(255, 139, 0);

    &::before {
      background-color: rgb(255, 171, 0);
      color: rgb(255, 250, 230);
    }
  }
  .Toastify__close-button--warning {
    color: rgb(255, 139, 0);
  }

  .Toastify__toast--success {
    background-color: rgb(227, 252, 239);
    color: rgb(0, 102, 68);

    &::before {
      background-color: rgb(54, 179, 126);
      color: rgb(227, 252, 239);
    }
  }
  .Toastify__close-button--success {
    color: rgb(0, 102, 68);
  }

  .Toastify__toast-body {
    margin: 0;
    padding: 8px 12px;
    min-height: 40px;
  }
  .Toastify__progress-bar {}
`;
