import React from 'react';
import { createBrowserRouter, RouterProvider }  from 'react-router-dom';
import { createGlobalStyle } from 'styled-components';
import { ThemeProvider } from 'styled-components';

import { ApplicationContextProvider } from '../contexts/Application';
import { BundleContextProvider } from '../contexts/Bundle';
import { AssessmentContextProvider } from '../contexts/Assessment';
import { QuestionContextProvider } from '../contexts/Question';
import WrappedToastContainer from './WrappedToastContainer';
import Bundle from './Bundle';
import PreviewQuestion from './PreviewQuestion';
import theme from '../theme';

const GlobalStyle = createGlobalStyle`
  *,
  *::before,
  *::after {
    box-sizing: border-box;
    outline: none;
  }

  html {
    margin: 0;
    padding: 0;
    font-family: ${props => props.theme.fonts.default.family};
    font-size: 10px;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
  }
  body {
    margin: 0;
    padding: 0;
    background-color: ${props => props.theme.colors.smoke};
    color: ${props => props.theme.colors.black};
    font-size: 1.4rem;
    line-height: 1.4;
  }

  h1 {
    font-size: 2.8rem;
  }

  h2 {
    font-size: 1.8rem;
  }

  button {
    font-family: ${props => props.theme.fonts.default.family};
    padding: 0 .8rem;
    font-weight: 600;
    border: 1px solid ${props => props.theme.colors.gray};
    background-image: linear-gradient(-180deg, #fff, #f5f8f9 99%);
    font-size: 1.4rem;
    height: 3.6rem;
    display: flex;
    flex-flow: row;
    border-radius: 4px;
    cursor: pointer;
    white-space: nowrap;
    align-items: center;
    position: relative;
    letter-spacing: -.024rem;
    box-sizing: border-box;

    &:hover {
      background-color: ${props => props.theme.colors.stone};
      background-image: none;
    }

    &.disabled {
      pointer-events: none;
      opacity: .5;
    }

    &.primary {
      background: ${props => props.theme.colors.blue};
      border: 1px solid ${props => props.theme.colors.ocean};
      color: ${props => props.theme.colors.white};

      &:hover {
        background: ${props => props.theme.colors.ocean};
        border: 1px solid ${props => props.theme.colors.storm};
      }
    }

    &.plain {
      border: 1px solid transparent;
      background-image: none;
      background-color: transparent;

      &:hover {
        background-color: ${props => props.theme.colors.stone};
        background-image: none;
      }
    }
  }

  .actions {
    margin-top: 2rem;
    display: flex;
    justify-content: flex-end;

    * + * {
      margin-left: 1rem;
    }
  }
`

const router = createBrowserRouter([
  {
    path: "/preview/:question",
    element: <PreviewQuestion />,
  },
  {
    path: '/:bundle/:applicant',
    element: <ApplicationContextProvider>
              <BundleContextProvider>
                <AssessmentContextProvider>
                  <QuestionContextProvider>
                    <Bundle />
                  </QuestionContextProvider>
                </AssessmentContextProvider>
              </BundleContextProvider>
            </ApplicationContextProvider>,
  },
  {
    path: '*',
    element: <h1>Invalid path.</h1>,
  },
]);

function App() {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle theme={theme}/>
      <WrappedToastContainer />
      <RouterProvider router={router} />
    </ThemeProvider>
  );
}

export default App;
