import React, { useContext, useState, useEffect } from 'react';
import styled, { ThemeContext } from 'styled-components';


const getTime = (question) => {
  let time = ((question.start + (question.limit * 1000)) - Date.now()) / 1000;
  time = Math.round(Math.max(time, 0));
  if (time > question.limit) {
    time = question.limit;
  }
  return time;
}

const Clock = ({ question, onTimeout, stopped }) => {
  const theme = useContext(ThemeContext);
  const [time, setTime] = useState(getTime(question));

  useEffect(
    () => {
      if (stopped) {
        return;
      }
      if (time === 0) {
        onTimeout();
        return;
      }
      const interval = setInterval(_ => {
        const newTime = getTime(question);
        setTime(newTime);
        if (newTime === 0) {
          onTimeout();
          clearInterval(interval);
        }
      }, 500);
      return _ => clearInterval(interval);
    },
    [time, stopped, question, onTimeout]
  );

  const left = time / question.limit * 100;

  const Box = styled.div`
    position: fixed;
    top: calc(5vh + 10rem);
    left: calc(50vw + 400px + 2rem);
    height: 8rem;
    width: 8rem;
    border-radius: 50%;
    ${props => time > 0 && `background: conic-gradient(${theme.colors.red} ${left}%, ${theme.colors.gray} ${left}%);`}
    ${props => time > 10 && `background: conic-gradient(${theme.colors.blue} ${left}%, ${theme.colors.gray} ${left}%);`}
    ${props => time === 0 && `background-color: ${theme.colors.red};`}
    ${props => (time === 0 || stopped) && 'opacity: .5;'}

    &::after {
      content: "${time}";
      font-size: 4rem;
      position: absolute;
      top: .5rem;
      left: .5rem;
      border-radius: 50%;
      height: 7rem;
      width: 7rem;
      line-height: 7rem;
      text-align: center;
      background-color: ${theme.colors.smoke};
    }
  `;

  return <Box />;
};

export default Clock;
