import React, { useContext, useState } from 'react';
import { toast } from 'react-toastify';

import useApplication from './Application';
import { AssessmentContext } from './Assessment';
import { getNextQuestion } from '../api.js';

const QuestionContext = React.createContext({ value: null, next: () => {} });

const QuestionContextProvider = ({ children }) => {
  const application = useApplication();
  const { value: assessment, set: setAssessment } = useContext(AssessmentContext);
  const [state, setState] = useState(null);

  const next = async (choice, paused) => {
    toast.info('Loading next question...', {autoClose: 1500});
    try {
      const context = await getNextQuestion({
        bundle: application.bundle.eid,
        applicant: application.applicant,
        assessment: assessment.eid,
        question: state && state.question && state.question.eid,
        choice,
        paused,
      });
      assessment.status = context.status;
      if (paused) {
        setState(null);
        setAssessment(null);
        toast.warning(`The assessment '${assessment.title}' was paused.`, {autoClose: 3000});
      } else if (context.question) {
        context.question.start = Date.now();
        setState(context);
      } else {
        setState(null);
        setAssessment(null);
        toast.success(`The assessment '${assessment.title}' was completed!`, {autoClose: 3000});
      }
    } catch (error) {
      toast.error('An error has ocurred. Reload the page to continue with the assessment.');
      console.log(error);
    }
  };

  return (
    <QuestionContext.Provider value={{ value: state, next: next }}>
      {children}
    </QuestionContext.Provider>
  );
};

const QuestionContextConsumer = QuestionContext.Consumer;


const useQuestion = () => {
  return useContext(QuestionContext).value;
};


export { useQuestion, QuestionContext, QuestionContextProvider, QuestionContextConsumer };
export default useQuestion;
