import React, { useContext, Fragment } from 'react';
import styled, { ThemeContext } from 'styled-components';

import useBundle from '../contexts/Bundle';
import { AssessmentContext } from '../contexts/Assessment';
import Assessment from './Assessment';
import Markdown from './Markdown';

const Bundle = () => {
  const bundle = useBundle();
  const { value: assessment, set: setAssessment } = useContext(AssessmentContext);
  const theme = useContext(ThemeContext);

  const Box = styled.div`
    margin: 5vh auto;
    padding: 2rem;
    max-width: 800px;
    background-color: ${theme.colors.white};
    border-radius: 4px;
    border: 1px solid ${theme.colors.gray};

    h1 {
      margin: 0;
    }

    ul {
      list-style-type: none;
      padding: 0;
      margin: 2rem 0 0 0;

      li {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: ${theme.colors.dirty};
        border: 1px solid ${theme.colors.gray};
        border-radius: 4px;
        padding: 10px;
        margin: 0;

        h2 {
          margin: 0;
          padding: 0;
        }

        .completed {
          display: block;
          color: ${theme.colors.green};
          font-weight: 600;
          font-size: 1.4rem;
          height: 3.6rem;
          line-height: 3.6rem;
          letter-spacing: -.024rem;
        }

        + li {
          margin-top: 2rem;
        }
      }
    }
  `;

  return (
    <Fragment>
      {bundle && !assessment && (
        <Box>
          <h1>{bundle.title}</h1>
          <Markdown value={bundle.description} />
          <ul>
            {bundle.assessments.map(item => {
              return (
                <li key={item.eid}>
                  <h2>{item.title}</h2>
                  {item.status === 'todo' && (<button onClick={() => setAssessment(item)}>Start Assessment</button>)}
                  {item.status === 'started' && (<button onClick={() => setAssessment(item)} className="primary">Continue Assessment</button>)}
                  {item.status === 'completed' && (<span className="completed">Assessment Completed</span>)}
                </li>
              )
            })}
          </ul>
        </Box>
      )}
      <Assessment />
    </Fragment>
  );
};

export default Bundle;
