import React, { useContext, Fragment, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import styled, { ThemeContext } from 'styled-components';
import { toast } from 'react-toastify';

import Markdown from './Markdown';
import Clock from './Clock';
import { getPreviewQuestion } from '../api';


const PreviewQuestion = (props) => {
  const theme = useContext(ThemeContext);
  const params = useParams();
  const [question, setQuestion] = useState(null);

  useEffect(
    () => {
      async function fetch() {
        toast.info('Loading question...', {autoClose: 1500});
        try {
          const question = await getPreviewQuestion(params.question);
          question.start = Date.now();
          setQuestion(question);
        } catch (error) {
          toast.error('Error loading question.');
          console.log(error);
        }
      }
      fetch();
      return () => {};
    },
    [params],
  );

  const COLUMNS = {
    '1N': 1,
    '2N': 2,
    '3N': 3,
    '4N': 4,
  };

  const Box = styled.div`
    position: relative;
    margin: 5vh auto;
    padding: 2rem;
    max-width: 800px;
    background-color: ${theme.colors.white};
    border-radius: 4px;
    border: 1px solid ${theme.colors.gray};

    .title {
      display: flex;
      margin: 0;
      justify-content: space-between;
      align-items: center;

      h1 {
        margin: 0;
      }
    }

    > hr {
      margin: 2rem 0 0 0;
      height: 10px;
      border: 0;
      border-radius: 4px;
      background-color: ${theme.colors.smoke};
      background-image: linear-gradient(90deg, ${theme.colors.blue} 0%, ${theme.colors.blue} 0%, ${theme.colors.smoke} 0%, ${theme.colors.smoke} 100%);

    }

    > .markdown {
      margin: 3rem 0 0 0;

      > p {
        font-size: 2.2rem;
        font-weight: 600;
      }
    }

    > ul {
      margin: 4rem 0 0 0;
      padding: 0;
      list-style-type: none;
      display: grid;
      grid-gap: 2rem;
      grid-template-columns: repeat(${COLUMNS[question && question.display] || 1}, 1fr);

      > li {
        margin: 0;
        padding: 1.5rem;
        border: 1px solid ${theme.colors.gray};
        cursor: pointer;
        font-size: 1.6rem;
        border-radius: 4px;

        &:hover {
          border: 1px solid ${theme.colors.blue};
        }

        > .markdown {
          margin-top: 0;
        }
      }
    }
  `;

  return (
    <Fragment>
      {question && (
        <Box>
          <Clock stopped question={question} />
          <div className="title">
            <h1>Preview Question</h1>
          </div>
          <hr />
          <Markdown className="markdown" value={question.text} />
          <ul>
            {question.choices.map(item => (
              <li key={item.eid}>
                <Markdown className="markdown" value={item.text} />
              </li>
            ))}
          </ul>
        </Box>
      )}
    </Fragment>
  );
};

export default PreviewQuestion;
